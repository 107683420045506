<template>
  <div>
    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>
    <div class="mb-8">
      <import-excel :onSuccess="loadDataInTable"/>
    </div>
    <vx-card>
      <!--Warehouses-->
      <div class="mt-5 mb-5">
        <label class="text-sm opacity-75">Almacén</label>
        <v-select label="name" :options="warehouses" :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="warehouse" class="mb-4 md:mb-0" name="warehouse"/>
      </div>
      <vs-table stripe pagination :max-items="10" search :data="tableData">
        <template slot="header">
          <h4>{{ sheetName }}</h4>
        </template>

        <template slot="thead">
          <vs-th :sort-key="heading" v-for="heading in header" :key="heading">{{ heading }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="col" v-for="(col, indexcol) in data[indextr]" :key="indexcol + col">
              {{ col }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-button @click="importData" class="mr-6 mt-5">Importar</vs-button>
    </vx-card>
  </div>
</template>

<script>
import ImportExcel from '@/components/excel/ImportExcel.vue'
import vSelect from 'vue-select'

import { db, FieldValue, auth } from '@/firebase/firebaseConfig'

export default {
  name: 'ImportStock',
  components: {
    ImportExcel,
    vSelect
  },
  data () {
    return {
      tableData: [],
      header: [],
      sheetName: '',
      initProgress: false,
      warehouses: [],
      products: [],
      warehouse: {}
    }
  },
  async created () {
    this.initProgress = true
    // Load warehouses
    let querySnapshot = await db.collection('warehouses').get()
    querySnapshot.forEach((doc) => {
      let obj = {
        id: doc.id,
        ...doc.data()
      }
      this.warehouses.push(obj)
    })
    // End
    this.initProgress = false
  },
  methods: {
    loadDataInTable ({ results, header, meta }) {
      this.header = header
      // Add state
      this.header.push('Estado')
      this.tableData = results
      console.log(results)
      this.tableData.forEach((d) => {
        this.$set(d, 'state', 'En espera')
        if (!d.ID) {
          this.$set(d, 'state', 'Falta ID')
          this.$set(d, 'ID', 'INCOMPLETO')
        }
        if (!d.Name) {
          this.$set(d, 'state', 'Falta nombre')
          this.$set(d, 'Name', 'INCOMPLETO')
        }
        if (!d.SKU) {
          this.$set(d, 'state', 'Falta SKU')
          this.$set(d, 'SKU', 'INCOMPLETO')
        }
        if (!d.STOCK && d.STOCK !== 0) {
          this.$set(d, 'state', 'Falta stock')
          this.$set(d, 'STOCK', 'INCOMPLETO')
        }
      })
      this.sheetName = meta.sheetName
    },
    async importData () {
      try {
        if (this.warehouse.id) {
          this.initProgress = true
          for await (let p of this.tableData) {
            if (p.state === 'En espera') {
              await this.updateProductStock(p)
            }
          }
          if (!this.hasErrors) {
            this.$vs.notify({
              color: 'success',
              title: 'Carga de información',
              text: 'Actualización realizada correctamente.'
            })
          } else {
            this.$vs.notify({
              color: 'warning',
              title: 'Carga de información',
              text: 'Actualización completada con errores.'
            })
          }
          this.initProgress = false
        }
      } catch (e) {
        this.initProgress = false
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    /**
     * Save data in firebase
     * @returns {Promise<void>}
     */
    async updateProductStock (p) {
      try {
        console.log(p)
        const doc = await db.collection('products').doc(p.ID).collection('stocks').doc(this.warehouse.id).get()
        if (doc.exists) {
          await db.collection('products').doc(p.ID).collection('stocks').doc(this.warehouse.id).update({
            stock: p.STOCK,
            productId: p.ID,
            warehouse: this.warehouse.name
          })
        } else {
          await db.collection('products').doc(p.ID).collection('stocks').doc(this.warehouse.id).set({
            stock: p.STOCK,
            productId: p.ID,
            warehouse: this.warehouse.name
          })
        }

        // Save movements
        await db.collection('products').doc(p.ID).collection('movements').add({
          createdAt: FieldValue.serverTimestamp(),
          motive: 'Reajuste de almacén masivo',
          userName: auth.currentUser.displayName,
          quantity: p.STOCK,
          type: 'input',
          id: this.warehouse.id,
          warehouse: this.warehouse,
          productId: p.ID,
          cost: 0,
          currency: {
            code: 'PEN'
          }
        })
        p.state = 'Ok'
      } catch (e) {
        console.log(e)
        this.hasErrors = true
        p.state = 'Error'
      }
    }
  }
}
</script>
